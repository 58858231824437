import { forwardRefWithAs } from 'utils/render'
import cx from 'classnames'
import React, { HTMLAttributes } from 'react'
import { Box } from '../Box'

export interface TabButtonProp extends HTMLAttributes<HTMLButtonElement> {
  className?: string
  target?: string
  isActive?: boolean
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const TabButton = forwardRefWithAs<'button', TabButtonProp>(
  (props, ref) => {
    const {
      as = 'button',
      className: classNameProps,
      isActive,
      Icon,
      children,
      ...rest
    } = props

    let rel: undefined | string

    if (as === 'a' && props.target === '_blank') {
      rel = 'noopener noreferrer'
    }

    const className = cx(
      'text-xs flex items-center justify-center space-x-1 h-8 border rounded-full bg-white duration-200 transition',
      {
        'border-blue-400 text-primary bg-blue-50': isActive,
        'text-gray-900 bg-gray-300': !isActive,
      },
      classNameProps,
    )

    return (
      <Box className={className} as={as} rel={rel} ref={ref} {...rest}>
        {Icon ? <Icon /> : null} <span>{children}</span>
      </Box>
    )
  },
)
