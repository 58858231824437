export const Logo = (props: any) => {
  const { isWhite = false, ...rest } = props
  const fill = isWhite ? '#ffffff' : '#002BE8'

  return (
    <svg
      width="105"
      height="36"
      viewBox="0 0 105 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_10265_14450)">
        <path
          d="M19.4617 17.7222V17.7975V18.0141C19.4617 20.6791 18.5151 22.9579 16.6125 24.8601C14.7099 26.7622 12.4097 27.7039 9.7214 27.7039C7.04259 27.7039 4.75188 26.7528 2.84926 24.8601C0.93718 22.9485 -0.00939547 20.6508 7.02891e-05 17.967C0.00953604 15.3963 1.03184 12.9668 2.85873 11.1494C4.76135 9.25661 7.04259 8.31494 9.7214 8.31494C11.2454 8.31494 12.6558 8.63511 13.9526 9.27544V2.74026C13.9526 1.22417 15.1831 0 16.7071 0C18.2311 0 19.4617 1.22417 19.4617 2.74026V17.7222ZM9.7214 22.2234C10.8952 22.2234 11.8985 21.8091 12.722 20.9898C13.5456 20.1611 13.9526 19.1724 13.9526 18.0141C13.9526 16.8559 13.5456 15.8671 12.722 15.0385C11.8985 14.2098 10.9046 13.8049 9.7214 13.8049C8.55711 13.8049 7.56321 14.2192 6.73022 15.0385C5.9067 15.8671 5.49021 16.8559 5.49021 18.0141C5.49021 19.1724 5.9067 20.1611 6.73022 20.9898C7.56321 21.8091 8.55711 22.2234 9.7214 22.2234Z"
          fill={fill}
        />
        <path
          d="M34.1146 10.5467C34.4081 10.2548 34.711 9.98173 35.0233 9.7369C33.5277 8.82348 31.8523 8.36206 30.0065 8.36206C27.3277 8.36206 25.037 9.31315 23.1343 11.1965C21.2317 13.0892 20.2852 15.3681 20.2852 18.033V18.2496V18.3249V24.9919C20.2852 26.4986 21.5157 27.7228 23.0302 27.7228C24.5447 27.7228 25.7753 26.4986 25.7753 24.9919V18.2025H25.7942V18.1272C25.7848 18.0989 25.7753 18.0707 25.7753 18.033C25.7753 16.8747 26.1918 15.886 27.0153 15.0573C27.8483 14.2381 28.8422 13.8237 30.0065 13.8237C30.6123 13.8237 31.1802 13.9367 31.7009 14.1722C32.2215 12.8444 33.0166 11.6391 34.1146 10.5467Z"
          fill={fill}
        />
        <path
          d="M41.6114 8.30542C44.2902 8.30542 46.5809 9.25651 48.493 11.1493C50.405 13.0514 51.3516 15.3303 51.3516 17.9952C51.3516 20.6695 50.3956 22.9578 48.493 24.86C46.5809 26.7621 44.2902 27.7038 41.6114 27.7038C38.9231 27.7038 36.6229 26.7527 34.7203 24.86C32.8177 22.9578 31.8711 20.679 31.8711 17.9952C31.8711 15.3303 32.8177 13.0514 34.7203 11.1493C36.6229 9.24709 38.9231 8.30542 41.6114 8.30542ZM41.6114 22.2233C42.7756 22.2233 43.7696 21.809 44.6025 20.9897C45.4355 20.1704 45.8426 19.1723 45.8426 17.9952C45.8426 16.8369 45.4261 15.8482 44.6025 15.0195C43.7696 14.2003 42.7756 13.7859 41.6114 13.7859C40.4471 13.7859 39.4532 14.2003 38.6202 15.0195C37.7872 15.8482 37.3802 16.8369 37.3802 17.9952C37.3802 19.1629 37.7967 20.161 38.6202 20.9897C39.4532 21.809 40.4471 22.2233 41.6114 22.2233Z"
          fill={fill}
        />
        <path
          d="M61.9239 8.32446C64.6027 8.32446 66.8934 9.26613 68.8055 11.1589C70.7175 13.0516 71.6641 15.3305 71.6641 18.0142C71.6641 20.6792 70.7081 22.958 68.8055 24.8507C66.8934 26.7435 64.6027 27.6852 61.9239 27.6852C60.4093 27.6852 58.9989 27.3744 57.6927 26.7435V33.2693C57.6927 34.7854 56.4621 36.0095 54.9381 36.0095C53.4141 36.0095 52.1836 34.7854 52.1836 33.2693V18.2967V18.2214V18.1178C52.1836 15.4717 53.2248 12.9198 55.118 11.0741C57.0111 9.23788 59.2734 8.32446 61.9239 8.32446ZM61.9239 22.2235C63.0881 22.2235 64.0821 21.8092 64.915 20.9899C65.748 20.1707 66.155 19.1725 66.155 18.0142C66.155 16.856 65.7386 15.8672 64.915 15.0386C64.0915 14.2099 63.0881 13.805 61.9239 13.805C60.7596 13.805 59.7657 14.2193 58.9327 15.0386C58.0997 15.8672 57.6927 16.856 57.6927 18.0142C57.6927 19.1725 58.1092 20.1612 58.9327 20.9899C59.7657 21.8092 60.7596 22.2235 61.9239 22.2235Z"
          fill={fill}
        />
        <path
          d="M82.2285 8.30566C84.9074 8.30566 87.1981 9.24733 89.1101 11.1401C91.0222 13.0328 91.9688 15.3117 91.9688 17.9954C91.9688 20.6604 91.0128 22.9392 89.1101 24.832C87.1981 26.7247 84.9074 27.6664 82.2285 27.6664C80.714 27.6664 79.3036 27.3556 77.9974 26.7247V33.2505C77.9974 34.7666 76.7668 35.9907 75.2428 35.9907C73.7188 35.9907 72.4883 34.7666 72.4883 33.2505V18.2779V18.2026V18.099C72.4883 15.4529 73.5295 12.901 75.4227 11.0553C77.3063 9.21908 79.5781 8.30566 82.2285 8.30566ZM82.2285 22.2047C83.3928 22.2047 84.3867 21.7904 85.2197 20.9711C86.0527 20.1519 86.4597 19.1537 86.4597 17.9954C86.4597 16.8372 86.0432 15.8484 85.2197 15.0198C84.3962 14.1911 83.3928 13.7862 82.2285 13.7862C81.0643 13.7862 80.0703 14.2005 79.2374 15.0198C78.4044 15.8484 77.9974 16.8372 77.9974 17.9954C77.9974 19.1537 78.4138 20.1424 79.2374 20.9711C80.0703 21.7904 81.0643 22.2047 82.2285 22.2047Z"
          fill={fill}
        />
        <path
          d="M98.2942 17.2891V24.9637C98.2942 26.4798 97.0637 27.7039 95.5397 27.7039C94.0157 27.7039 92.7852 26.4798 92.7852 24.9637V17.2891C92.7852 15.773 94.0157 14.5488 95.5397 14.5488C97.0637 14.5488 98.2942 15.773 98.2942 17.2891Z"
          fill={fill}
        />
        <path
          d="M104.626 17.3457V24.9638C104.626 26.4799 103.396 27.7041 101.872 27.7041C100.348 27.7041 99.1172 26.4799 99.1172 24.9638V17.3457C99.1172 15.8296 100.348 14.6055 101.872 14.6055C103.396 14.6055 104.626 15.8296 104.626 17.3457Z"
          fill={fill}
        />
        <path
          d="M101.841 13.8237C101.084 13.8237 100.44 13.5601 99.9101 13.0233C99.3705 12.4866 99.1055 11.8462 99.1055 11.1023C99.1055 10.3396 99.3705 9.69923 99.9101 9.16248C100.45 8.62573 101.093 8.36206 101.841 8.36206C102.608 8.36206 103.251 8.62573 103.791 9.16248C104.331 9.69923 104.596 10.3396 104.596 11.1023C104.596 11.8557 104.331 12.496 103.791 13.0233C103.261 13.5601 102.608 13.8237 101.841 13.8237Z"
          fill={fill}
        />
        <path
          d="M103.377 12.7314C103.007 13.061 103.14 13.6731 103.613 13.8237C104.087 13.9744 104.55 13.5601 104.446 13.0704C104.342 12.5901 103.746 12.4018 103.377 12.7314Z"
          fill={fill}
        />
        <path
          d="M101.85 10.8387C101.595 10.8387 101.358 10.754 101.169 10.6033C101.008 10.4715 100.762 10.5939 100.762 10.7916V10.8011C100.771 11.3943 101.292 11.884 101.888 11.8652C102.475 11.8463 102.939 11.3661 102.939 10.7822C102.939 10.7822 102.939 10.7822 102.939 10.7728C102.939 10.5751 102.693 10.4621 102.541 10.5845C102.352 10.7446 102.115 10.8387 101.85 10.8387Z"
          fill={!isWhite ? '#fff' : '#002BE8'}
        />
        <path
          d="M93.5898 13.0233C93.0502 12.4866 92.7852 11.8462 92.7852 11.1023C92.7852 10.3396 93.0502 9.69923 93.5898 9.16248C94.1293 8.62573 94.773 8.36206 95.5397 8.36206C96.297 8.36206 96.9406 8.62573 97.4707 9.16248C98.0103 9.69923 98.2753 10.3396 98.2753 11.1023C98.2753 11.8462 98.0103 12.496 97.4707 13.0233C96.9312 13.5601 96.2875 13.8237 95.5397 13.8237C94.773 13.8237 94.1293 13.5601 93.5898 13.0233Z"
          fill={fill}
        />
        <path
          d="M94.0063 12.7314C94.3754 13.061 94.2429 13.6731 93.7696 13.8237C93.2964 13.9744 92.8325 13.5601 92.9367 13.0704C93.0502 12.5901 93.6371 12.4018 94.0063 12.7314Z"
          fill={fill}
        />
        <path
          d="M95.5261 10.8387C95.7816 10.8387 96.0183 10.754 96.2076 10.6033C96.3685 10.4715 96.6146 10.5939 96.6146 10.7916V10.8011C96.6052 11.3943 96.0845 11.884 95.4882 11.8652C94.9013 11.8463 94.4375 11.3661 94.4375 10.7822C94.4375 10.7822 94.4375 10.7822 94.4375 10.7728C94.4375 10.5751 94.6836 10.4621 94.8351 10.5845C95.0244 10.7446 95.261 10.8387 95.5261 10.8387Z"
          fill={!isWhite ? '#fff' : '#002BE8'}
        />
      </g>
      <defs>
        <clipPath id="clip0_10265_14450">
          <rect width="104.625" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
