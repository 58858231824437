import { IconBell } from 'components/icons/components/IconBell'
import { IconButton } from 'components/ui/IconButton'
import { ROUTES } from 'constants/routes'
import { useNotificationCategory } from 'context/notification'
import { useTranslations } from 'next-intl'
import { BackLink } from '../BackLink'

export function NotificationButton() {
  const t = useTranslations()
  const { allUnReadNotification } = useNotificationCategory()
  return (
    <BackLink href={ROUTES.NOTIFICATION}>
      <IconButton
        count={allUnReadNotification}
        variant="link"
        scheme="primary"
        label={t('notification_count', { count: allUnReadNotification })}
        Icon={IconBell}
      />
    </BackLink>
  )
}
