import { useRouter } from 'next/router'
import { IconCart } from 'components/icons/components/IconCart'
import { IconButton, IconButtonProps } from 'components/ui/IconButton'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { useCartContext } from 'context/cart'
import { useTranslations } from 'next-intl'

type CartButtonProps = {
  schema?: IconButtonProps['scheme']
}

export function CartButton({ schema = 'primary' }: CartButtonProps) {
  const { asPath } = useRouter()
  const t = useTranslations()
  const { totalItemsInCart } = useCartContext()

  return (
    <Link
      href={{
        pathname: ROUTES.CART,
        query: { from: asPath },
      }}
    >
      <IconButton
        count={totalItemsInCart}
        variant="link"
        scheme={schema}
        label={t('cart_items_count', { count: totalItemsInCart })}
        Icon={IconCart}
      />
    </Link>
  )
}
