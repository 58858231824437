import { WithChildren } from 'types/common'
import { forwardRefWithAs } from 'utils/render'
import { Box } from '../Box'

export interface TabButtonsProps extends WithChildren {
  className?: string
}

export const TabButtonGroup = forwardRefWithAs<'div', TabButtonsProps>(
  (props, ref) => {
    const { children, className, as = 'div', ...rest } = props

    return (
      <Box className={className} ref={ref} as={as} {...rest}>
        {children}
      </Box>
    )
  },
)
