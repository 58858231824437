import { IconCommunication } from 'components/icons/components/IconCommunication'
import { IconPerformance } from 'components/icons/components/IconPerformance'
import { Logo } from 'components/ui/Logo'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { StatusBar } from 'components/common/StatusBar'
import { TabButton, TabButtonGroup } from 'components/ui/TabButtons'
import { IconFireMini } from 'components/icons/components/IconFireMini'
import { FeatureFlag } from 'flags'
import { useTranslations } from 'use-intl'
import { useMemo } from 'react'
import { Head } from 'components/common/Head'
import { Divider } from 'components/ui/Divider'

export function FeedHeader() {
  const { pathname } = useRouter()
  const t = useTranslations()

  const menuItems = useMemo(() => {
    return [
      { label: t('order_storm'), path: ROUTES.HOME, Icon: IconFireMini },
      { label: t('ranking'), path: ROUTES.RANK, Icon: IconPerformance },
      {
        label: t('promotion'),
        path: ROUTES.PROMOTIONS,
        Icon: IconCommunication,
      },
    ]
  }, [t])

  return (
    <>
      <Head
        isFixed={false}
        title={<Logo />}
        rightExtra={<StatusBar />}
        extra={
          <FeatureFlag flagKey="feed.tab_button">
            <TabButtonGroup className="px-4 grid grid-cols-3 gap-2 border border-t border-gray-400 py-2">
              {menuItems.map(({ Icon, label, path }) => {
                const isActive = path === pathname
                return (
                  <TabButton
                    as={Link}
                    href={path}
                    key={label}
                    isActive={isActive}
                    Icon={Icon}
                  >
                    {label}
                  </TabButton>
                )
              })}
            </TabButtonGroup>
          </FeatureFlag>
        }
      />
      <FeatureFlag
        flagKey="feed.tab_button"
        fallback={
          <div className="sm:container">
            <Divider color="400" />
          </div>
        }
      >
        {null}
      </FeatureFlag>
    </>
  )
}
