import React from 'react'
import cx from 'classnames'
import { WithChildren } from 'types/common'
import { forwardRefWithAs } from 'utils/render'
import { Box } from '../Box'

export interface CardProps extends WithChildren {
  hasShadow?: boolean
  hasSpacing?: boolean
  isRounded?: boolean
  className?: string
  onClick?: (event: React.SyntheticEvent) => void
}

export const Card = forwardRefWithAs<'div', CardProps>((props, ref) => {
  const {
    children,
    hasShadow = true,
    className,
    hasSpacing = true,
    isRounded = true,
    as = 'div',
    ...rest
  } = props

  return (
    <Box
      className={cx(
        'bg-white',
        {
          'shadow-100': hasShadow,
          'p-4': hasSpacing,
          'rounded-2xl': isRounded,
        },
        className,
      )}
      ref={ref}
      as={as}
      {...rest}
    >
      {children}
    </Box>
  )
})
