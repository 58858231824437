import { FeatureFlag } from 'flags'
import { CartButton } from './CartButton'
import { NotificationButton } from './NotificationButton'

export function StatusBar() {
  return (
    <div className="flex items-center space-x-1">
      <CartButton />
      <FeatureFlag flagKey="notification">
        <NotificationButton />
      </FeatureFlag>
    </div>
  )
}
